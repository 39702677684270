import React, { Fragment, useState } from 'react';
import { useTranslate, useRecordContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

import { isAuthorized } from '../../common/Authorize';

import { DuplicateGameDialog } from '../draftgames/DraftGameModal';

export default ({ size, variant, ...props }) => {
  const game = useRecordContext(props)
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const canCreateGame = isAuthorized(game, 'games', 'create');

  if (!canCreateGame) return null;

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<FileCopyOutlined />} onClick={() => setOpen(true)}>
      {translate('resources.draftGames.actions.duplicate_game')}
    </Button>
    <DuplicateGameDialog initialValues={{ isPublished: false, ...game }} isOpen={isOpen} component={Fragment} handleClose={() => setOpen(false)} hideRounds />
  </>
}
